import '@hotwired/turbo-rails';
import 'bootstrap';
import flatpickr from "flatpickr";
import jquery from "jquery";
import select2 from 'select2';

window.jQuery = jquery;
window.$ = jquery;

select2($);

document.addEventListener('turbo:load', () => {
  flatpickr(".flatpickr", {});
  $('.select2').select2({ theme: "bootstrap-5" });
})
